<template>
  <div style="width: 500px;background: white; padding: 50px">
    {{ this.$route.path }}
    <video-uploader :compact="true" need-cover />
  </div>
</template>
<script>
import VideoUploader from '@components/common/VideoUploader'
export default {
  data() {
    return {
      file: null,
      signature: '',
      sessionKey: ''
    }
  },
  methods: {},
  components: {
    VideoUploader
  }
}
</script>
<style lang="less" scoped></style>
